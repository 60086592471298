import React, { useState } from "react";
import { useQuery, gql } from '@apollo/client';
import ReactGA from 'react-ga4';

const GET_FEATURED_PRODUCTS = gql`
  query {
    productCollection {
        entity_id
        sku
        name
        image
        poa_product
        min_price
        special_price
        price
        final_price
        type_id
    }
  }
`;

const Homepage = () => {
    const [baseUrl] = useState("https://mage247p1.23digital-prod4.com/");
    const { loading, error, data } = useQuery(GET_FEATURED_PRODUCTS);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    const handleButtonClick = () => {
        ReactGA.event({
           category: 'User',
           action: 'Clicked a add to cart button'
        });
    };
    return (
        <>
        <div className="column main">
            <input name="form_key" type="hidden" value="t59JrlNWmjKNx9Fd" />
            <div id="authenticationPopup" style={{display: "none"}}>
                <div className="block-authentication" style={{display: "none"}}>
                    <div className="block block-new-customer" data-label="or">
                        <div className="block-title">
                            <strong id="block-new-customer-heading" role="heading" aria-level="2">Checkout as a new customer</strong>
                        </div>
                        <div className="block-content" aria-labelledby="block-new-customer-heading">
                            <p>Creating an account has many benefits:</p>
                            <ul>
                                <li>See order and shipping status</li>
                                <li>Track order history</li>
                                <li>Check out faster</li>
                            </ul>
                            <div className="actions-toolbar">
                                <div className="primary">
                                    <a href="/#" onClick={e => e.preventDefault()} className="action action-register primary">
                                        <span>Create an Account</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="block block-customer-login" data-label="or">
                        <div className="block-title">
                            <strong id="block-customer-login-heading" role="heading" aria-level="2">Checkout using your account</strong>
                        </div>
                        <div data-role="checkout-messages" className="messages"></div>
                        <div className="block-content" aria-labelledby="block-customer-login-heading">
                            <form className="form form-login" method="post" id="login-form">
                                <div className="fieldset login" data-hasrequired="* Required Fields">
                                    <div className="field email required">
                                        <label className="label" htmlFor="customer-email"><span>Email Address</span></label>
                                        <div className="control">
                                            <input
                                                name="username"
                                                id="customer-email"
                                                type="email"
                                                className="input-text"
                                                data-mage-init='{"mage/trim-input":{}}'
                                                data-validate="{required:true, 'validate-email':true}"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="field password required">
                                        <label htmlFor="pass" className="label"><span>Password</span></label>
                                        <div className="control">
                                            <input name="password" type="password" className="input-text" id="pass"  data-validate="{required:true}" autoComplete="off" />
                                        </div>
                                    </div>
                                    <input name="captcha_form_id" type="hidden" value="user_login" data-scope="" />
                                    <div className="actions-toolbar">
                                        <input name="context" type="hidden" value="checkout" />
                                        <div className="primary">
                                            <button type="submit" className="action action-login secondary" name="send" id="send2">
                                                <span>Sign In</span>
                                            </button>
                                        </div>
                                        <div className="secondary">
                                            <a className="action" href="/#" onClick={e => e.preventDefault()}>
                                                <span>Forgot Your Password?</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="widget block block-static-block">
                <div className="blocks-promo">
                    <a href="/#" onClick={e => e.preventDefault()} className="block-promo home-main">
                        <img src={baseUrl+"/media/wysiwyg/home/home-main.jpg"} alt="" />
                        <span className="content bg-white">
                            <span className="info">New Luma Yoga Collection</span>
                            <strong className="title">Get fit and look fab in new seasonal styles</strong>
                            <span className="action more button">Shop New Yoga</span>
                        </span>
                    </a>
                    <div className="block-promo-wrapper block-promo-hp">
                        <a href="/#" onClick={e => e.preventDefault()} className="block-promo home-pants">
                            <img src={baseUrl+"/media/wysiwyg/home/home-pants.jpg"} alt="" />
                            <span className="content">
                                <strong className="title">20% OFF</strong>
                                <span className="info">Luma pants when you shop today*</span>
                                <span className="action more icon">Shop Pants</span>
                            </span>
                        </a>
                        <a href="/#" onClick={e => e.preventDefault()} className="block-promo home-t-shirts">
                            <span className="image"><img src={baseUrl+"/media/wysiwyg/home/home-t-shirts.png"} alt="" /></span>
                            <span className="content">
                                <strong className="title">Even more ways to mix and match</strong>
                                <span className="info">Buy 3 Luma tees get a 4th free</span>
                                <span className="action more icon">Shop Tees</span>
                            </span>
                        </a>
                        <a href="/#" onClick={e => e.preventDefault()} className="block-promo home-erin">
                            <img src={baseUrl+"/media/wysiwyg/home/home-erin.jpg"} alt="" />
                            <span className="content">
                                <strong className="title">Take it from Erin</strong>
                                <span className="info">Luma founder Erin Renny shares her favorites!</span>
                                <span className="action more icon">Shop Erin Recommends</span>
                            </span>
                        </a>
                        <a href="/#" onClick={e => e.preventDefault()} className="block-promo home-performance">
                            <img src={baseUrl+"/media/wysiwyg/home/home-performance.jpg"} alt="" />
                            <span className="content bg-white">
                                <strong className="title">Science meets performance</strong>
                                <span className="info">Wicking to raingear, Luma covers&nbsp;you</span>
                                <span className="action more icon">Shop Performance</span>
                            </span>
                        </a>
                        <a href="/#" onClick={e => e.preventDefault()} className="block-promo home-eco">
                            <img src={baseUrl+"/media/wysiwyg/home/home-eco.jpg"} alt="" />
                            <span className="content bg-white">
                                <strong className="title">Twice around, twice as nice</strong>
                                <span className="info">Find conscientious, comfy clothing in our eco-friendly collection</span>
                                <span className="action more icon">Shop Eco-Friendly</span>
                            </span>
                        </a>
                    </div>
                </div>
                <div className="content-heading">
                    <h2 className="title">Hot Sellers</h2>
                    <p className="info">Here is what`s trending on Luma right now</p>
                </div>
                <div className="block widget block-products-list grid">
                    <div className="block-content">
                        <div className="products-grid grid">
                            <ol className="product-items widget-product-grid">
                                {data.productCollection.map((item) => (
                                    <li className="product-item" key={item.entity_id}>
                                        <div className="product-item-info">
                                            <a href="/#" onClick={e => e.preventDefault()} className="product-item-photo" title={item.name}>
                                                <span className={`product-image-container product-image-container-${item.entity_id}`} style={{ width: '268px' }}>
                                                    <span className="product-image-wrapper" style={{ paddingBottom: "100%" }}>
                                                        <img
                                                            className="product-image-photo"
                                                            src={baseUrl+"/media/catalog/product"+item.image}
                                                            loading="lazy"
                                                            width="240"
                                                            height="300"
                                                            alt={item.name}
                                                        />
                                                    </span>
                                                </span>
                                            </a>
                                            <div className="product-item-details">
                                                <strong className="product-item-name">
                                                    <a href="/#" onClick={e => e.preventDefault()} className="product-item-link"> {item.name} </a>
                                                </strong>
                                                <div className="price-box price-final_price" data-role="priceBox">
                                                    <span className="price-container price-final_price tax weee">
                                                        <span id="old-price-14-widget-product-grid" data-price-type="finalPrice" className="price-wrapper">
                                                            <span className="price">$ { item.price.toFixed(2) }</span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="product-item-inner">
                                                    <div className="product-item-actions">
                                                        <div className="actions-primary">
                                                            <button title="Add to Cart" className="action tocart primary" onClick={handleButtonClick}>
                                                                <span>Add to Cart</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};
 
export default Homepage;