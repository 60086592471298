import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
        <>
    		<footer className="page-footer">
                <div className="footer content">
                    <div className="block newsletter">
                        <div className="title"><strong>Newsletter</strong></div>
                        <div className="content">
                            <form className="form subscribe" action="#" method="post" id="newsletter-validate-detail">
                                <div className="field newsletter">
                                    <div className="control">
                                        <label htmlFor="newsletter">
                                            <span className="label"> Sign Up for Our Newsletter: </span>
                                            <input name="email" type="email" id="newsletter" placeholder="Enter your email address" />
                                        </label>
                                    </div>
                                </div>
                                <div className="actions">
                                    <button className="action subscribe primary" title="Subscribe" type="submit" aria-label="Subscribe">
                                        <span>Subscribe</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <ul className="footer links">
                        <li className="nav item"><Link to="/privacy-policy">Privacy and Cookie Policy</Link></li>
                        <li className="nav item"><Link to="/aboutus">About us</Link></li>
                        <li className="nav item"><Link to="/contact">Contact Us</Link></li>
                    </ul>
                </div>
            </footer>
            <small className="copyright">
                <span>Copyright © 2024-present Magento, Inc. All rights reserved.</span>
            </small>
        </>
	);
};
 
export default Footer;