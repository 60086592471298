import React from "react";
import Header from "./components/Header/header";
import Footer from "./components/Footer/footer";
import Home from "./pages";
import About from "./pages/about";
import Contact from "./pages/contact";
import Privacy from "./pages/privacy";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import ReactGA from 'react-ga4';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
const client = new ApolloClient({
  uri: 'https://mage247p1.23digital-prod4.com/graphql',
  cache: new InMemoryCache(),
});

function App() {
    ReactGA.initialize('G-QWF55MT6S0');
  return (
    <ApolloProvider client={client}>
        <Router>
            <div className="page-wrapper">
                <Header />
                <main id="maincontent" className="page-main">
                  <div className="columns">
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route path="/aboutus" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/privacy-policy" element={<Privacy />} />
                    </Routes>
                  </div>
                  </main>
                <Footer />
            </div>
        </Router>
    </ApolloProvider>
  );
}

export default App;
