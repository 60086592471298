import React from "react";

const Header = () => {
	return (
        <>
		<header className="page-header">
            <div className="panel wrapper">
                <div className="panel header">
                    <ul className="header links">
                        <li className="greet welcome" data-bind="scope: 'customer'">
                            <span className="not-logged-in" data-bind="text: 'Default welcome msg!'">Default welcome msg!</span>
                        </li>

                        <li className="link authorization-link" data-label="or">
                            <a href="/#">Sign In</a>
                        </li>
                        <li><a href="/#" id="idENS3gnNI">Create an Account</a></li>
                    </ul>
                </div>
            </div>
            <div className="header content">
                <span data-action="toggle-nav" className="action nav-toggle"><span>Toggle Nav</span></span>
                <a className="logo" href="/#" title="" aria-label="store logo">
                    <img src="./assets/images/logo.svg" title="" alt="" width="170" />
                </a>
                <div data-block="minicart" className="minicart-wrapper">
                    <a className="action showcart" href="/#" data-bind="scope: 'minicart_content'">
                        <span className="text">My Cart</span>
                        <span className="counter qty empty" data-bind="css: { empty: !!getCartParam('summary_count') == false &amp;&amp; !isLoading() },blockLoader: isLoading">
                            <span className="counter-number"></span>
                            <span className="counter-label"></span>
                        </span>
                    </a>
                    <div tabIndex="-1" role="dialog" className="ui-dialog ui-corner-all ui-widget ui-widget-content ui-front mage-dropdown-dialog" aria-describedby="ui-id-28" style={{display: 'none'}}>
                        <div className="block block-minicart ui-dialog-content ui-widget-content" data-role="dropdownDialog" id="ui-id-28" style={{display: 'block'}}>
                            <div id="minicart-content-wrapper" data-bind="scope: 'minicart_content'">
                                <div className="block-title">
                                    <strong>
                                        <span className="text" data-bind="i18n: 'My Cart'">My Cart</span>
                                        <span
                                            className="qty empty"
                                            data-bind="css: { empty: !!getCartParam('summary_count') == false },
                               attr: { title: $t('Items in Cart') }, text: getCartParam('summary_count')"
                                            title="Items in Cart"
                                        ></span>
                                    </strong>
                                </div>
                                <div className="block-content">
                                    <button
                                        type="button"
                                        id="btn-minicart-close"
                                        className="action close"
                                        data-action="close"
                                        data-bind="attr: {title: $t('Close')},click: closeMinicart()"
                                        title="Close"
                                    >
                                        <span data-bind="i18n: 'Close'">Close</span>
                                    </button>
                                    <strong className="subtitle empty" data-bind="i18n: 'You have no items in your shopping cart.'">You have no items in your shopping cart.</strong>
                                    <div id="minicart-widgets" className="minicart-widgets">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block block-search">
                    <div className="block block-title"><strong>Search</strong></div>
                    <div className="block block-content">
                        <form className="form minisearch" id="search_mini_form" action="#" method="get">
                            <div className="field search">
                                <label className="label" htmlFor="search" data-role="minisearch-label">
                                    <span>Search</span>
                                </label>
                                <div className="control">
                                    <input
                                        id="search"
                                        type="text"
                                        name="q"
                                        placeholder="Search entire store here..."
                                        className="input-text"
                                        maxLength="128"
                                    />
                                    <div id="search_autocomplete" className="search-autocomplete"></div>
                                    <div className="nested">
                                        <a className="action advanced" href="/#" data-action="advanced-search"> Advanced Search </a>
                                    </div>
                                </div>
                            </div>
                            <div className="actions">
                                <button type="submit" title="Search" className="action search" aria-label="Search" disabled="">
                                    <span>Search</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <ul className="compare wrapper">
                    <li className="item link compare" data-bind="scope: 'compareProducts'" data-role="compare-products-link">
                        <a href="/#" className="action compare no-display" title="Compare Products">
                            Compare Products <span className="counter qty"></span>
                        </a>
                    </li>
                </ul>
            </div>
        </header>
        <div className="sections nav-sections">
            <div className="section-items nav-sections-items mage-tabs-disabled" role="tablist">
                <div className="section-item-title nav-sections-item-title active" data-role="collapsible" role="tab" data-collapsible="true" tabIndex="0">
                    <a className="nav-sections-item-switch" data-toggle="switch" href="#store.menu"> Menu </a>
                </div>
                <div className="section-item-content nav-sections-item-content" id="store.menu" data-role="content" role="tabpanel" aria-hidden="false">
                    <nav className="navigation" data-action="navigation">
                        <ul id="ui-id-1" role="menu" tabIndex="0" className="ui-menu ui-widget ui-widget-content" aria-activedescendant="ui-id-12">
                            <li className="level0 nav-1 category-item first level-top ui-menu-item">
                                <a href="/#" className="level-top ui-menu-item-wrapper" id="ui-id-2" tabIndex="-1" role="menuitem"><span>What's New</span></a>
                            </li>
                            <li className="level0 nav-2 category-item level-top parent ui-menu-item">
                                <a href="/#" className="level-top ui-menu-item-wrapper" aria-haspopup="true" id="ui-id-3" tabIndex="-1" role="menuitem">
                                    <span className="ui-menu-icon ui-icon ui-icon-caret-1-e"></span><span>Women</span>
                                </a>
                                <ul className="level0 submenu ui-menu ui-widget ui-widget-content ui-front" role="menu" style={{display: "none", top: "47px", left: '0px'}}>
                                    <li className="level1 nav-2-1 category-item first parent ui-menu-item">
                                        <a href="/#" aria-haspopup="true" id="ui-id-4" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper">
                                            <span className="ui-menu-icon ui-icon ui-icon-caret-1-e"></span><span>Tops</span>
                                        </a>
                                        <ul className="level1 submenu ui-menu ui-widget ui-widget-content ui-front expanded" role="menu" style={{display: "none", top: "36px", left: "0px"}}>
                                            <li className="level2 nav-2-1-1 category-item first ui-menu-item">
                                                <a href="/#" id="ui-id-5" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper"><span>Jackets</span></a>
                                            </li>
                                            <li className="level2 nav-2-1-2 category-item ui-menu-item">
                                                <a href="/#" id="ui-id-6" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper">
                                                    <span>Hoodies &amp; Sweatshirts</span>
                                                </a>
                                            </li>
                                            <li className="level2 nav-2-1-3 category-item ui-menu-item">
                                                <a href="/#" id="ui-id-7" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper"><span>Tees</span></a>
                                            </li>
                                            <li className="level2 nav-2-1-4 category-item last ui-menu-item">
                                                <a href="/#" id="ui-id-8" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper"><span>Bras &amp; Tanks</span></a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="level1 nav-2-2 category-item last parent ui-menu-item">
                                        <a href="/#" aria-haspopup="true" id="ui-id-9" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper">
                                            <span className="ui-menu-icon ui-icon ui-icon-caret-1-e"></span><span>Bottoms</span>
                                        </a>
                                        <ul className="level1 submenu ui-menu ui-widget ui-widget-content ui-front expanded" role="menu" style={{display: "none"}}>
                                            <li className="level2 nav-2-2-1 category-item first ui-menu-item">
                                                <a href="/#" id="ui-id-10" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper"><span>Pants</span></a>
                                            </li>
                                            <li className="level2 nav-2-2-2 category-item last ui-menu-item">
                                                <a href="/#" id="ui-id-11" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper"><span>Shorts</span></a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="level0 nav-3 category-item level-top parent ui-menu-item">
                                <a href="/#" className="level-top ui-menu-item-wrapper" aria-haspopup="true" id="ui-id-12" tabIndex="-1" role="menuitem">
                                    <span className="ui-menu-icon ui-icon ui-icon-caret-1-e"></span><span>Men</span>
                                </a>
                                <ul className="level0 submenu ui-menu ui-widget ui-widget-content ui-front" role="menu" style={{display: "none", top: "47px", left: "0px"}}>
                                    <li className="level1 nav-3-1 category-item first parent ui-menu-item">
                                        <a href="/#" aria-haspopup="true" id="ui-id-13" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper">
                                            <span className="ui-menu-icon ui-icon ui-icon-caret-1-e"></span><span>Tops</span>
                                        </a>
                                        <ul className="level1 submenu ui-menu ui-widget ui-widget-content ui-front expanded" role="menu"  style={{display: "none"}}>
                                            <li className="level2 nav-3-1-1 category-item first ui-menu-item">
                                                <a href="/#" id="ui-id-14" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper"><span>Jackets</span></a>
                                            </li>
                                            <li className="level2 nav-3-1-2 category-item ui-menu-item">
                                                <a href="/#" id="ui-id-15" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper">
                                                    <span>Hoodies &amp; Sweatshirts</span>
                                                </a>
                                            </li>
                                            <li className="level2 nav-3-1-3 category-item ui-menu-item">
                                                <a href="/#" id="ui-id-16" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper"><span>Tees</span></a>
                                            </li>
                                            <li className="level2 nav-3-1-4 category-item last ui-menu-item">
                                                <a href="/#" id="ui-id-17" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper"><span>Tanks</span></a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="level1 nav-3-2 category-item last parent ui-menu-item">
                                        <a href="/#" aria-haspopup="true" id="ui-id-18" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper">
                                            <span className="ui-menu-icon ui-icon ui-icon-caret-1-e"></span><span>Bottoms</span>
                                        </a>
                                        <ul className="level1 submenu ui-menu ui-widget ui-widget-content ui-front expanded" role="menu"  style={{display: "none"}}>
                                            <li className="level2 nav-3-2-1 category-item first ui-menu-item">
                                                <a href="/#" id="ui-id-19" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper"><span>Pants</span></a>
                                            </li>
                                            <li className="level2 nav-3-2-2 category-item last ui-menu-item">
                                                <a href="/#" id="ui-id-20" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper"><span>Shorts</span></a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="level0 nav-4 category-item level-top parent ui-menu-item">
                                <a href="/#" className="level-top ui-menu-item-wrapper" aria-haspopup="true" id="ui-id-21" tabIndex="-1" role="menuitem">
                                    <span className="ui-menu-icon ui-icon ui-icon-caret-1-e"></span><span>Gear</span>
                                </a>
                                <ul className="level0 submenu ui-menu ui-widget ui-widget-content ui-front" role="menu"  style={{display: "none", top: "47px", left: "0px"}}>
                                    <li className="level1 nav-4-1 category-item first ui-menu-item">
                                        <a href="/#" id="ui-id-22" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper"><span>Bags</span></a>
                                    </li>
                                    <li className="level1 nav-4-2 category-item ui-menu-item">
                                        <a href="/#" id="ui-id-23" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper"><span>Fitness Equipment</span></a>
                                    </li>
                                    <li className="level1 nav-4-3 category-item last ui-menu-item">
                                        <a href="/#" id="ui-id-24" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper"><span>Watches</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li className="level0 nav-5 category-item level-top parent ui-menu-item">
                                <a href="/#" className="level-top ui-menu-item-wrapper" aria-haspopup="true" id="ui-id-25" tabIndex="-1" role="menuitem">
                                    <span className="ui-menu-icon ui-icon ui-icon-caret-1-e"></span><span>Training</span>
                                </a>
                                <ul className="level0 submenu ui-menu ui-widget ui-widget-content ui-front" role="menu"  style={{display: "none", top: "47px", left: "0px"}}>
                                    <li className="level1 nav-5-1 category-item first last ui-menu-item">
                                        <a href="/#" id="ui-id-26" tabIndex="-1" role="menuitem" className="ui-menu-item-wrapper"><span>Video Download</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li className="level0 nav-6 category-item last level-top ui-menu-item">
                                <a href="/#" className="level-top ui-menu-item-wrapper" id="ui-id-27" tabIndex="-1" role="menuitem"><span>Sale</span></a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="section-item-title nav-sections-item-title" data-role="collapsible" role="tab" data-collapsible="true" aria-controls="store.links" aria-selected="false"  tabIndex="0">
                    <a className="nav-sections-item-switch" data-toggle="switch" href="#store.links"> Account </a>
                </div>
                <div className="section-item-content nav-sections-item-content" id="store.links" data-role="content" role="tabpanel" style={{display: "none"}}>
                    <ul className="header links">
                        <li className="greet welcome" data-bind="scope: 'customer'">
                            <span className="not-logged-in" data-bind="text: 'Default welcome msg!'">Default welcome msg!</span>
                        </li>

                        <li className="link authorization-link" data-label="or">
                            <a href="/#">Sign In</a>
                        </li>
                        <li><a href="/#" id="idENS3gnNI_mobile">Create an Account</a></li>
                    </ul>
                </div>
            </div>
        </div>
        </>
	);
};
 
export default Header;