import React from "react";
import DocumentMeta from 'react-document-meta';
import { useQuery, gql } from '@apollo/client';
import Parser from 'html-react-parser';
import ReactGA from 'react-ga4';

const GET_ABOUT_US_CMS = gql`{cmsPage(identifier: "about-us") {identifier url_key title content content_heading}}`;

const About = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: 'About us', });
  const meta = {
      title: 'About us',
      description: 'This is test About us page On React demo for react and seo practice.',
      canonical: window.location.href,
      meta: {
        charset: 'utf-8',
        name: {
            title: 'About us',
            keywords: 'react,meta,document,html,tags',
            robots: 'INDEX,FOLLOW',
        }
      }
    };
  const { data, error, loading } = useQuery(GET_ABOUT_US_CMS);
  if (loading) return <div className="loader-container"><div className="cust-loader"><img src="/assets/images/loader-2.gif" alt="Loading..." /></div></div>;
  if (error) return <p>Error: {error.message}</p>;
  return (
    <>
      <DocumentMeta {...meta}>
        <h1 className="page-title">
          <span className="page-title-wrapper" >{data.cmsPage.content_heading}</span>    
        </h1>
        <div>
          {Parser(data.cmsPage.content)}
        </div>
      </DocumentMeta>
    </>
  );
};
 
export default About;