import React from "react";
import Homepage from "./Homepage/homepage";
import DocumentMeta from 'react-document-meta';
import ReactGA from 'react-ga4';
 
const Home = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: 'React SEO Demo Home', });
    const meta = {
      title: 'React SEO Demo Home',
      description: 'This is test demo for react and seo practice.',
      canonical: window.location.href,
      meta: {
        charset: 'utf-8',
        name: {
            title: 'React SEO Demo Home',
            keywords: 'react,meta,document,html,tags',
            robots: 'INDEX,FOLLOW',
        }
      }
    };
    return (
        <>
        <DocumentMeta {...meta}>
            <Homepage />
        </DocumentMeta>
        </>
    );
};
 
export default Home;