import React, { useState } from 'react';
import DocumentMeta from 'react-document-meta';
import { useMutation, gql } from '@apollo/client';
import ReactGA from 'react-ga4';

const CONTACT_FORM_MUTATION = gql`
  mutation ContactUs($input: ContactUsInput!) {
    contactUs(input: $input) {
      status
    }
  }
`;

function ContactUsForm() {
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState({});
  /*const [submitContactForm, { data, error }] = useMutation(CONTACT_FORM_MUTATION);*/
  const [contactUs] = useMutation(CONTACT_FORM_MUTATION, {
});
  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: 'Contact Us', });
  const handleSubmit = (event) => {
    event.preventDefault();
    /*const formData = { name, email, telephone, comment };*/

    /*Validate form data*/
    const errors = {};
    if (!name) {
      errors.name = 'Please enter your name';
    }
    if (!email) {
      errors.email = 'Please enter your email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      errors.email = 'Invalid email address';
    }
    if (!telephone) {
      errors.telephone = 'Please enter your telephone';
    }
    if (!comment) {
      errors.comment = 'Please enter a comment';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      contactUs({
        variables: { input: { email, name, telephone, comment } },
      });
      setName('');
      setEmail('');
      setTelephone('');
      setComment('');
    }
  };

  const meta = {
    title: 'Contact Us',
    description: 'This is test Contact us page On React demo for react and seo practice.',
    canonical: window.location.href,
    meta: {
      charset: 'utf-8',
      name: {
        title: 'Contact Us',
        keywords: 'react,meta,document,html,tags',
        robots: 'INDEX,FOLLOW',
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
        <h1 className="page-title">
          <span className="page-title-wrapper" >Contact Us</span>    
        </h1>
        <div className="contact-info cms-content">
            <p className="cms-content-important">We love hearing from you, our Luma customers. Please contact us about anything at all. Your latest passion, unique health experience or request for a specific product. We’ll do everything we can to make your Luma experience unforgettable every time. Reach us however you like</p>
            <div className="block block-contact-info">
                <div className="block-title">
                    <strong>Contact Us Info</strong>
                </div>
                <div className="block-content">
                    <div className="box box-phone">
                        <strong className="box-title">
                            <span>Phone</span>
                        </strong>
                        <div className="box-content">
                            <a href="tel:18004038838"><span className="contact-info-number">1-800-403-8838</span></a>
                            <p>Call the Luma Helpline for concerns, product questions, or anything else. We’re here for you 24 hours a day - 365 days a year.</p>
                        </div>
                    </div>
                    <div className="box box-design-inquiries">
                        <strong className="box-title">
                            <span>Apparel Design Inquiries</span>
                        </strong>
                        <div className="box-content">
                            <p>Are you an independent clothing designer? Feature your products on the Luma website! Please direct all inquiries via email to: <a href="mailto:example@example.com">example@example.com</a></p>
                        </div>
                    </div>
                    <div className="box box-press-inquiries">
                        <strong className="box-title">
                            <span>Press Inquiries</span>
                        </strong>
                        <div className="box-content">
                            <p>Please direct all media inquiries via email to: <a href="mailto:test@example.com">test@example.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input type="text" value={name} onChange={(event) => setName(event.target.value)} />
            {errors.name && <div style={{ color: 'red' }}>{errors.name}</div>}
          </label>
          <br />
          <label>
            Email:
            <input type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
            {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
          </label>
          <br />
          <label>
            Telephone:
            <input type="text" value={telephone} onChange={(event) => setTelephone(event.target.value)} />
            {errors.telephone && <div style={{ color: 'red' }}>{errors.telephone}</div>}
          </label>
          <br />
          <label>
            Message:
            <textarea value={comment} onChange={(event) => setComment(event.target.value)} />
            {errors.comment && <div style={{ color: 'red' }}>{errors.comment}</div>}
          </label>
          <br />
          <button type="submit">Send</button>
        </form>
      </div>
    </DocumentMeta>
  );
}

export default ContactUsForm;